body {
  margin: 0;
}
html,body,#root{
  height: 100%;
}

.ant-table-cell {
  padding: 10px!important;
}

.react-pdf__Page__annotations{
  display: none;
}

.popconfirmMonitor{
  .ant-popconfirm-buttons{
    display: none;
  }
}
.Drawer_history {
  .ant-table-tbody{
    tr{
      td{
        vertical-align: middle;
      }
    }
  }
  .History {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    line-height: 28px;
    position: relative;
    padding-left: 9px;
    &::before {
      width: 3px;
      height: 13px;
      position: absolute;
      top: 4px;
      left: 0;
      content: '';
      display: block;
      background: #2243E7;
    }
  }
}

.ant-layout-content{
  background: url("assets/images/app/bg.png");
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}


#root {
  height: 100%;
  .App {
    background: url("assets/images/app/bg.png");
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
.Modal_login{
  .ant-modal{
    background: #FFFFFF!important;
    border-radius: 12px 12px 12px 12px;
    .ant-modal-content{
      background: url("assets/images/login/Background.png") no-repeat ;
      background-position:-100px -110px
    }
  }
}


