
/*样式初始化*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
form,
fieldset/*fieldset 元素可将表单内的相关元素分组*/,
legend,
input,
button,
textarea,
p,
blockquote,
th,
td
{
  line-height: 0;
  margin:0;
  padding:0;
  outline: 0;
  /*outline （轮廓）是绘制于元素周围的一条线，位于边框边缘的外围，可起到突出元素的作用。*/
  font:inherit;
  /*inherit 关键字指定一个属性应从父元素继承它的值。*/
  vertical-align: baseline;/*baseline 默认。元素放置在父元素的基线上。*/
}


a{
  color:#555;
  text-decoration: none;
}

a:hover{
  text-decoration: underline;
}

img{
  border:none;
}

ol,ul,li{
  list-style: none;
}

table{
  border-collapse: collapse;/*为表格设置合并边框模型*/
}

react-pdf__Page__annotations,
.react-pdf__Page__textContent {
  display: none;
}

