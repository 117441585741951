.header{
  width: 100%;
  position: fixed;
  height: 85px;
  display: flex;
  align-items: center;
  z-index: 100;
  //background: #E4EDFF;
  background: url("../../assets/images/app/bg.png");
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  .top{
    position: absolute;
    top: -456px;
    left: -200px;
    z-index: 100;
  }
  &.active{
    border-bottom: 1px solid #2B3858;
  }
  .logoHeader{
    position: absolute;
    width: 168px;
    height: 45px;
    cursor: pointer;
    margin-left: 22%;
    z-index: 102;
  }
  .headerMenu{
    display: flex;
    li{
      margin-right: 15px;
    }
  }
  .subMenu{
    li{
      display: flex;
      span{
        margin-right: 15px;
      }
    }
  }
  ul {
    display: flex;
    li{
      font-size: 16px;
      font-weight: 500;
      color: #0F172A;
      line-height: 24px;
      margin-left: 32px;
      cursor: pointer;
      &.active{
        color: #296dff;
        position: relative;
        &::before{
          content: '';
          display: block;
          position: absolute;
          bottom: -7px;
          left: -2px;
          width: 70px;
          height: 6px;
          background: #2e54d9;
          border-radius: 3px;
        }
        &:nth-of-type(2)::before{
          bottom: -7px;
          left: -2px;
          width: 75px;
          height: 6px;
        }
        &:nth-of-type(3)::before{
          bottom: -7px;
          left: -2px;
          width: 45px;
          height: 6px;
        }
        &:nth-of-type(4)::before{
          bottom: -7px;
          left: -2px;
          width: 50px;
          height: 6px;
        }
      }
    }
    margin-right: 82px;
  }
  .count{
    margin-right: 14%;
  }
  .Sign{
    width: 113px;
    height: 48px;
    border-radius: 50px 50px 50px 50px;
    border: 2px solid #004AFF;
    margin-right: 14%;
  }
}
