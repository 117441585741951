.login{
  form{
    margin-top: 20px;
  }
  .sending{
    display: flex;
    .send_mess{
      margin-left: 7px;
      height: 56px;
    }
  }
  .logo_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    .login_name{
      font-size: 42px;
      font-weight: bold;
      color: #000000;
      span{
        color: #4461F2;
      }
    }
    .left{
      .logo{
        margin-top: 35px;
        margin-left: 32px;
        width: 150px;
        height: 40px;
      }
      p:nth-of-type(1){
        margin-left: 36px;
        margin-top: 55px;
        font-size: 52px;
        font-weight: bold;
        color: #000000;
        line-height: 77px;
        -webkit-background-clip: text;
      }
      p:nth-of-type(2){
        width: 286px;
        margin-top: 75px;
        margin-left: 60px;
        font-size: 22px;
        font-weight: 500;
        color: #000000;
      }
      p:nth-of-type(3){
        margin-bottom: 73px;
        margin-left: 60px;
        font-size: 22px;
        font-weight: 500;
        color: #4461F2;
      }
    }
    .right{
      .nav{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        //margin-right: 20px;
        margin-top: 25px;
        span{
          cursor: pointer;
          font-size: 17px;
          font-weight: bold;
          color: #4461F2;
          line-height: 26px;
          -webkit-background-clip: text;
          position: relative;

          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 25px;
          width: 112px;
          height: 42px;
          background: #C6D6FF;
          box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.15);
          border-radius: 21px 21px 21px 21px;
          &.active{
            color: #4461F2;
            background: none;
            box-shadow: none;
            &::before{
              position: absolute;
              display: block;
              width: 26px;
              height: 3px;
              content: '';
              left: 42px;
              bottom: -8px;
              background: #4461F2;
              font-size: 17px;
              font-weight: bold;
              color: #4461F2;
            }
          }
        }
      }
      .ant-form{
        margin-top: 23px;
        .recover{
          cursor: pointer;
          text-align: right;
          font-size: 14px;
          font-weight: 500;
          color: #C7C7C7;
          //line-height: 77px;
          letter-spacing: 1px;
          -webkit-background-clip: text;
        }
        .account_item{
          margin-bottom: 0;
          .account{
            cursor: pointer;
            margin-top: 20px;
            font-size: 14px;
            font-weight: 400;
            color: #4461F2;
            -webkit-background-clip: text;
          }
        }
        .form_submit{
          .submit{
            width: 495px;
            height: 60px;
            margin-top: 20px;
            background: #4461F2;
            box-shadow: 0px 12px 21px 4px rgba(68,97,242,0.15);
            border-radius: 10px 10px 10px 10px;
            font-size: 19px;
            font-weight: bold;
            color: #FFFFFF;
          }
          .Google{
            width: 495px!important;
            height: 60px;
            background: #F3F3F4;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #E7E7E7;
            font-size: 16px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000000;
          }
        }
      }
      input {
        background: #ffffff!important;
      }
    }
  }
  .createUser{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    input {
      background: #ffffff!important;
    }
    .login_name{
      font-size: 42px;
      font-weight: bold;
      color: #000000;
      span{
        color: #4461F2;
      }
    }
    .ant-form-item-explain-error{
      text-align: left;
    }
    .submit{
      width: 495px;
      height: 60px;
      //margin-top: 20px;
      background: #4461F2;
      box-shadow: 0px 12px 21px 4px rgba(68,97,242,0.15);
      border-radius: 10px 10px 10px 10px;
      font-size: 19px;
      font-weight: bold;
      color: #FFFFFF;
    }
    .Google{
      width: 495px!important;
      height: 60px;
      background: #F3F3F4;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #E7E7E7;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000;
    }
  }
}
